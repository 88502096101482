import React from "react"
import Helmet from "react-helmet";

import { Navigation, Content } from "../components";
import "../styles/main.scss";
import flag from "../images/icons/flag.svg";
import people from "../images/icons/people.svg";
import map from "../images/icons/map.svg";
import { Link } from "gatsby";
import dashboard from "../images/image01.png";


// --------------------------------------------------------------------------------
// Page Test
// --------------------------------------------------------------------------------
function PageTest(): JSX.Element {
    return <div className="border">
        <div className="row border">
            <div className="col border d-sm-none">
                <p>xs &lt;= 576</p>
            </div>
            <div className="col border d-none d-sm-block d-md-none">
                <p>sm &gt;576 &amp; &lt;=768</p>
            </div>
            <div className="col border d-none d-md-block d-lg-none">
                <p>md &gt;768 &amp; &lt;=992</p>
            </div>
            <div className="col border d-none d-lg-block">
                <p>lg &gt;992</p>
            </div>
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Page Banner
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    return <div className="qbl-banner pb-4">
        <div className="row row-cols-1 row-cols-sm-2 gy-4">
            <div className="col py-2 px-4 text-center text-sm-start">
                <h1>Reports, analytics and dashboards for HR people</h1>

                <p className="pb-2">
                    Deliver stunning reports, created from the data you already have.
                </p>
                <p>
                    Understand your organisation, deliver valuable insight.
                    Communicate your stories.
                </p>

                <div className="d-grid d-sm-inline gap-2">
                    <Link className="btn btn-primary px-sm-4" to="/contact-us/">Request a demo</Link>
                </div>
            </div>
            {/* <div className="col overflow-hidden border px-0"> */}
            <div className="col px-2 pe-sm-4 pe-md-2 pe-lg-0 px-sm-0">
                {/* <img src={dashboard} alt="Dashboard screenshot" width="872px" height="554px" className="mw-100 mh-100" /> */}
                <img src={dashboard} alt="Dashboard screenshot" className="mw-100 xmh-100" />
            </div>
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Page Specialisms
// --------------------------------------------------------------------------------
function PageSpecialisms(): JSX.Element {
    const talent =
        <>
            <h2>Talent management &amp; performance</h2>
            <p><strong>HR Generalists and HRBPs:</strong> engage your stakeholders with data‑driven conversations.</p>
            <p><Link to="/people-analytics/">More information</Link></p>
        </>;

    const learning =
        <>
            <h2>Learning &amp; development</h2>
            <p><strong>HR Generalists and HRBPs:</strong> engage your stakeholders with data‑driven conversations.</p>
            <p><Link to="/people-analytics/">More information</Link></p>
        </>;

    const diversity =
        <>
            <h2>Diversity, inclusion &amp; belonging</h2>
            <p><strong>HR leaders:</strong> lead your people agenda strategy using HR metrics.</p>
            <p><Link to="/workforce-planning/">More information</Link></p>
        </>;

    const compAndBens =
        <>
            <h2>Reward / compensation &amp; benefits</h2>
            <p><strong>Senior execs:</strong> strategic workforce insights for senior leaders.</p>
            <p><Link to="/dashboards-and-reports/">More information</Link></p>
        </>;

    const empExperience =
        <>
            <h2>Employee experience / engagement</h2>
            <p><strong>Change leads:</strong> plan, monitor and track workforce change programmes.</p>
            <p><Link to="/transformation-analytics/">More information</Link></p>
        </>;

    const orgDesign =
        <>
            <h2>Organisational design</h2>
            <p><strong>Change leads:</strong> plan, monitor and track workforce change programmes.</p>
            <p><Link to="/transformation-analytics/">More information</Link></p>
        </>;

    const recruitment =
        <>
            <h2>Resourcing / recruitment &amp; selection</h2>
            <p><strong>Change leads:</strong> plan, monitor and track workforce change programmes.</p>
            <p><Link to="/transformation-analytics/">More information</Link></p>
        </>;

    const general =
        <>
            <h2>General HR</h2>
            <p><strong>Change leads:</strong> plan, monitor and track workforce change programmes.</p>
            <p><Link to="/transformation-analytics/">More information</Link></p>
        </>;

    return <div className="row row-cols-1 row-cols-sm-3 pb-4 gy-4">
        <div className="col px-4">
            {talent}
        </div>
        <div className="col px-4">
            {diversity}
        </div>
        <div className="col px-4">
            {compAndBens}
        </div>
        <div className="col px-4 pe-sm-0">
            {empExperience}
        </div>
        <div className="col px-4 pe-sm-0">
            {orgDesign}
        </div>
        <div className="col px-4 pe-sm-0">
            {learning}
        </div>
        <div className="col px-4 pe-sm-0">
            {recruitment}
        </div>
        <div className="col px-4 pe-sm-0">
            {general}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Page Roles
// --------------------------------------------------------------------------------
function PageRoles(): JSX.Element {
    const analytics =
        <div className="flex-column d-flex">
            <div className="flex-grow-1 mb-2">
                <h2>People analytics</h2>
                <p><strong>HR Generalists and HRBPs:</strong> engage your stakeholders with data‑driven conversations.</p>
            </div>
            <div>
                <Link to="/people-analytics/">More information</Link>
            </div>
        </div>;

    const planning =
        <div className="flex-column d-flex">
            <div className="flex-grow-1 mb-2">
                <h2>Workforce planning</h2>
                <p><strong>HR leaders:</strong> lead your people agenda strategy using HR metrics.</p>
            </div>
            <div>
                <Link to="/workforce-planning/">More information</Link>
            </div>
        </div>;

    const dashboards =
        <div className="flex-column d-flex">
            <div className="flex-grow-1 mb-2">
                <h2>Dashboards &amp; reports</h2>
                <p><strong>Senior execs:</strong> strategic workforce insights for senior leaders.</p>
            </div>
            <div>
                <Link to="/dashboards-and-reports/">More information</Link>
            </div>
        </div>;

    const transformation =
        <div className="flex-column d-flex">
            <div className="flex-grow-1 mb-2">
                <h2>Transformation analytics</h2>
                <p><strong>Change leads:</strong> plan, monitor and track workforce change programmes.</p>
            </div>
            <div>
                <Link to="/transformation-analytics/">More information</Link>
            </div>
        </div>;

    return <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 pb-2 mb-4 gy-5 d-flex align-items-stretch">
        <div className="col px-4 d-flex">
            {analytics}
        </div>
        <div className="col px-4 d-flex">
            {planning}
        </div>
        <div className="col px-4 d-flex">
            {dashboards}
        </div>
        <div className="col px-4 d-flex pe-sm-0">
            {transformation}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Page Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Deliver key HR insights</h2>
            <ul>
                <li>Diversity and inclusivity</li>
                <li>Talent retention</li>
                <li>Staff turnover</li>
                <li>Workforce costs</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />
            <h2>Stunning people reports</h2>
            <ul>
                <li>Pre-built HR metrics and graphs</li>
                <li>Combine existing datasets from HR, finance, sales etc.</li>
                <li>Historical trends and performance</li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Scenario planning and what&ndash;ifs</h2>
            <ul>
                <li>Identify patterns and trends</li>
                <li>Plan for different outcomes</li>
                <li>Analyse targets and results</li>
                <li>Drive behavioural change</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-2 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
    </div>;
}


const IndexPage = () => <>
    <Helmet title="Qebal People Analytics" htmlAttributes={{ lang: 'en' }}>
        <meta name="description" content="Create HR reports and dashboards from your existing HR database." />
    </Helmet>
    <Navigation />
    <Content>
        <div className="container-lg">
            <PageBanner />
            {/* <PageSpecialisms /> */}
            <PageRoles />
            <PageFeatures />
        </div>
    </Content>

</>;

export default IndexPage;
